import React, { useState, useMemo, useEffect } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import {
    Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemText,
    IconButton, Button, Avatar, Typography
} from '@mui/material';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import ContactsDialog from './ContactsDialog';
import SearchDialog from './FindMore/SearchDialog';
import DeleteContactDialog from './DeleteContactDialog';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// const Pulse = styled('div')(({ theme }) => ({
//     animation: '$pulseAnimation 1s infinite alternate',
//     '@keyframes pulseAnimation': {
//         '0%': { transform: 'scale(1)', opacity: 1 },
//         '100%': { transform: 'scale(1.1)', opacity: 0.8 }
//     }
// }));

// Define the animation outside the styled component
const pulseAnimation = keyframes`
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1.1); opacity: 0.8; }
`;

// Apply the animation within the styled component
const Pulse = styled('div')(({ theme }) => ({
    animation: `${pulseAnimation} 1s infinite alternate`
}));

const StickyContainer = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
    padding: '10px'
}));

function RecipientsDialog({ open, onClose, mailListId, contactId, MailListName, selectedList, campaignMailer, exclusion }) {
    const [loading, setLoading] = useState(true);
    const [recipients, setRecipients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState({});
    const [showNewContacts, setShowNewContacts] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteRecipients, setDeleteRecipients] = useState(null);

    const onMakeExclusionList = async (recipientArray) => {
        let exclusionListName = window.prompt("Please enter Exclusion List Name", "Exclusion List" + " : " + MailListName + " - " + new Date().toDateString())
        if (exclusionListName?.length > 0) {
            var qs = process.env.REACT_APP_DATABASE + "api/createAndAssociateExclusion"
            var contactsArray = recipientArray
            var result = await axios.put(qs, {
                contactsArray: contactsArray,
                mailListId: mailListId,
                selectedList: selectedList,
                exclusionListName: exclusionListName,
                contactId: contactId,
                folderId: selectedList.folderId,
                campaignMailer: campaignMailer
                      

            })
        }
    }
    const fetchData = async () => {
        if (!mailListId) return [];
        const qs = `${process.env.REACT_APP_DATABASE}api/getMailerContacts`;
        try {
            const recipientInfo = await axios.post(qs, { id: mailListId });
            return recipientInfo.data;
        } catch (error) {
            console.error("problem with axios mail contacts call", error);
            return [];
        }
    };

    const fetchAndSetData = async () => {
        var recipientInfo = await fetchData().catch((error)=>{console.log(error)})
            
        
        if (recipientInfo) {
            setRecipients(recipientInfo)
            return true
        }
        else {
            setRecipients([])
            return false
        }


    }

    useEffect(() => {
       

        if (open && !showNewContacts) {
            setLoading(true);
            fetchAndSetData().then(() => { setLoading(false); })
        }
    }, [open, showNewContacts, mailListId]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleDeleteRecipients = (recipientsArray) => {
        setDeleteRecipients(recipientsArray);
        setShowDelete(true);
    };

    const handleAddRecipient = () => {
        setShowNewContacts(true);
    };

    const filteredRecipients = useMemo(() => {
   
        return recipients.filter(recipient => {
            const recipientString = Object.values(recipient).join(' ').toLowerCase();
            return recipientString.includes(searchTerm);
        });
    }, [recipients, searchTerm]);

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {exclusion && <span style={{ color: '#f50057', marginRight: 4 }}>Exclusion</span>}
                        <MailOutlineIcon style={{ marginRight: 4 }} />
                        Recipient List: {MailListName}

                      
                    </span>
                </DialogTitle>
                <DialogContent>
                    <StickyContainer>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Search Mail List Recipients"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        
                        <div style={{ display: 'flex' }}>
                            <Pulse>
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={handleAddRecipient}
                                    variant="contained"
                                    sx={{ fontSize: 12 }}
                                >
                                    Get More Recipients
                                    {/* {searchTerm.length > 0 ? `containing '${searchTerm}'` : ""} */}
                                </Button>
                            </Pulse>

                            <Button
                                startIcon={<RemoveCircleOutline />}
                                variant='outlined'
                                sx={{ marginLeft: 5, fontSize: 12 }}
                                onClick={() => handleDeleteRecipients(filteredRecipients)}
                            >
                                Remove All Recipients Shown
                            </Button>
                        </div>
                    </StickyContainer>
                    {loading ? <p>Loading...</p> : (
                        <List>
                            {filteredRecipients.map((recipient) => (
                                <ListItem
                                    key={recipient.id}
                                    onClick={() => { window.open("/contact?id=" + recipient.id)}}
                                    secondaryAction={
                                        <IconButton edge="end" onClick={(event) => {
                                            event.stopPropagation(); // This will stop the event from bubbling up to parent elements
                                            handleDeleteRecipients([recipient]);
                                        }}>
                                            <RemoveCircleOutline />
                                        </IconButton>
                                    }
                                >
                                    <Avatar
                                        style={{ marginRight: 2, borderRadius: '10%', objectFit: 'contain' }}
                                        imgProps={{ style: { objectFit: 'contain' } }}
                                        src={recipient.images?.[0]?.img || recipient?.office?.company?.images?.[0]?.img}
                                    />
                                    <ListItemText
                                        primary={`${recipient.FirstName} ${recipient.LastName}`}
                                        secondary={
                                            <>
                                                {recipient.Title && <Typography component="span" variant="body2">{recipient.Title}</Typography>}
                                                <Typography component="span" variant="body2" style={{ display: 'block' }}>{recipient.Email}</Typography>
                                                <Typography variant="body2">{recipient?.office?.City},{recipient?.office?.State} </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
            </Dialog>
            <SearchDialog
                open={showNewContacts}
                handleClose={() => {
                    setSearchTerm('');
                    setShowNewContacts(false);
                }}
                contactId={contactId}
                mailListId={mailListId}
                setContacts={() => { console.log("set contacts") }}
                MailListName={MailListName}
                   recipients={recipients}
                fetchData={fetchAndSetData}
           
            />

            <DeleteContactDialog
                exclusion={exclusion}
                open={showDelete}
                onClose={() => setShowDelete(false)}
                deleteRecipients={deleteRecipients}
                MailListName={MailListName}
                setSearchTerm={setSearchTerm}
                onMakeExclusionList={onMakeExclusionList}
                onRemoveEverywhere={async () => {
                    // Map over deleteRecipients to create an array of lowercase emails
                    const emailArray = deleteRecipients.map(recipient => recipient?.Email?.toLowerCase());

                    // Try to post to your API and catch any errors
                    try {
                        const result = await axios.post(process.env.REACT_APP_DATABASE + 'api/removeContactFromListAPI', {
                    
                            mailListId,
                            deleteRecipients
                        });

                        // Filter the current recipients to remove those whose emails were just deleted
                        if (result) {
                            const updatedRecipients = recipients.filter(recipient =>
                                !emailArray.includes(recipient.Email.toLowerCase())
                            );

                            // Update the state with the filtered recipients list
                            setRecipients(updatedRecipients);
                        }
                    } catch (error) {
                        console.log(error);
                        alert("Failed to delete contacts.");
                    }
                }}

            />
        </>
    );
}

export default RecipientsDialog;
