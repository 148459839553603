import { useState, useEffect } from "react";
import { Check as CheckIcon, ExpandMore as SelectorIcon } from '@mui/icons-material'
import React from "react";
import { Combobox } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Switch, Tooltip } from "@mui/material";
import { getCookie, createCookie } from "../Cookies";

import  { FlyToInterpolator } from 'deck.gl';
import axios from "axios";
import { getBuildingFromLatLon, getZoomFromElevation, mobileAndTabletCheck } from "../../Search/Deck";
import { WebMercatorViewport } from "deck.gl";
import getBounds from "geolib/es/getBounds";
import { getViewportElevation } from "../../Search/Deck";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchBarCombo({
  properties,
  selectedProperty,
  setSelectedProperty,
  getSearchData,
  setSearchProperties,
  contactId,
  searchResults,
  setSearchResults,
  addProperties,
  setAddingNewProperties,
  height,
  setGeometry,
  setInitialViewState,
  searchDetailRef,
  // filters,
  controllers,
  setControllers,





}) {
  const [query, setQuery] = useState("");
  const [firstBigSearch, setFirstBigSearch] = useState(true);
  const [goOnClick, setGoOnClick] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const viewport = new WebMercatorViewport({ // this is in case we don't have a camera pointed anywhere.  Probably should never happen
    width: 600,
    height: 400,
    longitude: -122.45,
    latitude: 37.78,
    zoom: 16,
    pitch: 50,
    bearing: 0
  });
  var filteredproperties = [];
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    // Show the tooltip on load
    setShowTooltip(true);

    // Hide the tooltip after 5 seconds
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  if (!properties) {
    return (
      <img className="w-6 h-6" src="https://images.cubicsearch.com/Wait.gif" />
    );
  }

  const handleInputEnter = () => {
    // Show the tooltip when the input is entered
    setShowTooltip(true);
  };


  if (properties.empty) {
    if (getCookie("searchbar") && getCookie("searchbar") !== "") {
      var tempfilteredproperties = JSON.parse(getCookie("searchbar")).entries;
      filteredproperties = tempfilteredproperties.filter((property) => {
        return property.name.toLowerCase().includes(query.toLowerCase());
      });
    }
  } else {

    var count = 0;
    if (query === "") {
      if (getCookie("searchbar") && getCookie("searchbar") !== "") {
        var filteredproperties = JSON.parse(getCookie("searchbar")).entries; //properties.slice(0, 10)


      }
    } // properties
    else {


      filteredproperties = properties.filter((property) => {

        var splitArray = [query]
        var propertySplitArray = [property.name]
        if (query.includes(" ")) {
          splitArray = query.split(" ") // the split array is the query broken up into an array of words
        }
        if (property && property.name && property.name.includes(" ")) {
          propertySplitArray = property.name.split(" ")
        }



        if (property.name) {

          if (
            query && splitArray && (count < 50) &&
            (
              (property.type === "Move Map Here" || property.type === "Campus") &&
              splitArray.some(substring =>
                substring && property.name.toLowerCase().includes(substring.toLowerCase()))
            )
            ||
            property.name.toLowerCase().includes(query.toLowerCase())

            // || splitArray.every((r) => {
            //   return property.name.toLowerCase().includes(r.toLowerCase())
            // }

            // )

          ) {
            count++;
            return true//property.name.toLowerCase().includes(query.toLowerCase());
          }
        }
      });

    }
  }

  function mapReload() {
    searchDetailRef.current.mapReload()


  }
  function isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor == Object;
  }
  const goToParcel = async (property) => {

    var qs;

    var yesFetch = true;

    var filters = {}


    if (goOnClick) {
      if (property.type === "Parcel") {
        navigate(
          "/parcel?id=" + replaceString("parcel-", "", property.id));
      }
      if (property.type === "Building") {
        // navigate(
        //   "property?id=" + replaceString("building-", "", property.id);
      }
      if (property.type === "Available Space") {
        navigate(
          "/available?id=" + replaceString("avail-", "", property.id));
        return true
      }
      if (property.type === "Lease Transaction") {
        navigate(
          "/available?id=" + replaceString("comp-", "", property.id));
        return true
      }
      if (property.type === "Contact") {
        navigate(
          "/contact?id=" + replaceString("contact-", "", property.id));
      }
      if (property.type === "Campus") {
        navigate(
          "/search?campus=" + encodeURI(replaceString("campus-", "", property.id)));
      }
      // if (property.type === "City") {
      //   navigate( "search?location=" + property.id;
      // }
    }
    if (searchDetailRef && searchDetailRef.current) {
      filters = searchDetailRef.current.getFilters()
    }
    if (property.type === "Contact") {

      navigate("/contact?id=" + replaceString("contact-", "", property.id))
      // })
    }
    
    if ((property.type === "Building") && isObject(filters)) {
      // process.env.REACT_APP_DATABASE + "/"
   
      filters = searchDetailRef?.current?.getFilters()
      if (!filters) {
        filters = {}
      }
      filters.single = parseInt(replaceString("building-", "", property.id))
      filters.location = null
      filters.campus = null
      filters.compsCheck = false
      filters.allCheck = false
      filters.availablesCheck = false


    }
    else if (property.type === "Available Space") {
      navigate("/available?id=" + replaceString("avail-", "", property.id));
      return true
    }
    else if (property.type === "Lease Transaction") {
      navigate(
        "available?id=" + replaceString("comp-", "", property.id));
      return true
    } else if (property.type === "Campus") {
      // process.env.REACT_APP_DATABASE + "/"
      if (isObject(filters) && searchDetailRef && searchDetailRef?.current) {
        filters = searchDetailRef?.current?.getFilters()

        filters.campus = replaceString("campus-", "", property.id)
        filters.location = null
        filters.single = null
        filters.compsCheck = false
        filters.allCheck = false
        filters.availablesCheck = false
      }
      // } else if (property.type === "City") {
      //   // process.env.REACT_APP_DATABASE + "/"
      //   filters = searchDetailRef.current.getFilters()
      //   filters.location = property.id
      //   filters.campus = null
      //   filters.single = null
    } else if (property.type == "Move Map Here") {//   if (property.type === "Move Map Here") {
      yesFetch = false

      if (window.location.href.includes("search?")) {

        setGeometry(property.id)
        let coordinates = [property.id.location.lng,
        property.id.location.lat]

        var qs = process.env.REACT_APP_DATABASE +
          "api/getElevationLocal?latitude=" + (coordinates[1] +
            "&longitude=" + coordinates[0])


        var elevationData = await axios.get(qs).catch((error) => {

          console.log(error)
        })

        var building = await getBuildingFromLatLon(coordinates[1], coordinates[0]).catch((error) => {
          console.log(error)
        })

        var height = 0
        if (building && building.properties && building.properties.height) {
          if (!isNaN(building.properties.height)) {
            height = building.properties.height
          }
        }
        var elevation = 0
        if (elevationData.data.elevation) {
          if (!isNaN(elevationData.data.elevation)) {
            elevation = elevationData.data.elevation
          }
        }
        var totalHeight = elevation + height
        coordinates.push(totalHeight)

        searchDetailRef?.current?.setSearchedLocations(
          [
            {
              "type": "Feature",
              "properties": {
              },
              "geometry": {
                "type": "Point",
                "coordinates": coordinates
              },
            }])// we time this out so it might be last to load and cover up the rest


        var buildingTopZoom = getZoomFromElevation(totalHeight + 2500)
       


        searchDetailRef.current.setReloadOnMove(true)

        var currentViewState = {
          latitude: property.id.location.lat,
          longitude: property.id.location.lng,
          zoom: buildingTopZoom,
          // minZoom: 10,
          // maxZoom: maxZoom,
          bearing: 0,
          pitch: 50,
          transitionDuration: 500,
          onTransitionEnd: mapReload

        }

        var viewStateCookie = { ViewState: currentViewState, searchResults: null, filters: filters, URL: window.location.href }
        var postResult = await axios.post(
          process.env.REACT_APP_DATABASE +
          "api/createViewStates", {
          userId: contactId,
          ViewState: viewStateCookie,


        }
        ).catch((error) => {
          console.log(error)
        })



        if (elevationData.data.elevation > 1000) {
          currentViewState.maxZoom = 13.5
        }
        else if (elevationData.data.elevation > 100) {
          currentViewState.maxZoom = 14
        }
        else {
          currentViewState.maxZoom = 19.5
        }

        setInitialViewState(currentViewState)



      }
      else {

        navigate("/search?moveMeHere=" + encodeURI(JSON.stringify(property.id)))


      }
    }
    if (yesFetch) {

      if (contactId && filters) { filters.contactId = contactId }
      else if (filters) { filters.contactId = getCookie("guestId") }
      if (!filters) {
        filters = {}
      }
      filters.query = query
      setWaiting(true)
      var newSearchResults = await axios.post(process.env.REACT_APP_DATABASE +
        "api/buildingcampus", filters).catch((error) => {
          console.log(error)
        })

      if (addProperties){
      addProperties(newSearchResults?.data);
        setAddingNewProperties(true);

        searchDetailRef?.current?.setSearchedLocations(null)

        if (!goOnClick) {
          searchResults = searchResults?.concat(newSearchResults?.data)

        }
        else {
          searchResults = newSearchResults?.data
        }
        // searchDetailRef.current.setRefs(
        //   searchResults.reduce((acc, result) => {
        //     acc[result.id] = React.createRef();
        //     return acc;
        //   }, {})
        // );
        // }
        if (searchResults?.length > 0) {
          var coordinates = searchResults.map((result) => ({
            latitude: result.Latitude,
            longitude: result.Longitude,
          }));
          var bounds = getBounds(coordinates);

          const { longitude, latitude, zoom } = (bounds && !isNaN(bounds.minLng)) ? viewport.fitBounds([ // if we have bounds we have lat and long from it
            [bounds.minLng - .0005, bounds.minLat - .0005],
            [bounds.maxLng + .0005, bounds.maxLat + .0005]
          ]) : { longitude: null, latitude: null, zoom: null }

          setWaiting(false)

          var currentViewState = {


            latitude: latitude,
            longitude: longitude,
            zoom: zoom > 18 ? 18 : zoom,
            // minZoom: window.screen.width > 700 ? 4 : 8,
            maxZoom: window.screen.width > 700 ? 22 : 22,
            bearing: 0,
            pitch: 50,
            transitionDuration: 3000,
            transitionInterpolator: new FlyToInterpolator()

          }

          var viewStateCookie = { ViewState: currentViewState, searchResults: searchResults, filters: filters, URL: window.location.href }
          var postResult = await axios.post(
            process.env.REACT_APP_DATABASE +
            "api/createViewStates", {
            userId: contactId,
            ViewState: viewStateCookie,


          }
          ).catch((error) => {
            console.log(error)
          })

          var elevation = await getViewportElevation(null, searchResults && searchResults.length > 0 && searchResults[0])
          if (elevation.elevation > 1000) {
            currentViewState.maxZoom = 13.5
          }
          else if (elevation.elevation > 100) {
            currentViewState.maxZoom = 14
          }
          else {
            currentViewState.maxZoom = 19.5
          }
          setInitialViewState(bounds ? // this is the par that is tricky.
            //Have we moved the camera and should we even mess with this, or is it a new search that needs the camera pointed here
            // we basically need a drag state thing that turns on if we drag, otherwise we point the camera
            // howver this is the first load so there is no way we dragged it.  This would only be the case if it was not the initial
            currentViewState :
            null); // 3. we set the initital view state
          setSearchResults(searchResults);

        }
        else {
          // alert(
          //   "No Results"
          // )
        }
      }
      else {

        var location = newSearchResults.data[0]
        var newSpot = {
          "location": {
            "lat": location.Latitude,
            "lng": location.Longitude, "elevation": location.Elevation
          }, "viewport": { "northeast": { "lat": location.Latitude + .01, "lng": location.Longitude + .01 }, "southwest": { "lat": location.Latitude - .01, "lng": location.Longitude - .01 } }
        }
        navigate("/search?moveMeHere=" + encodeURI(JSON.stringify(newSpot)))
        
      }
     


    }
    // }
  };

  if (!goOnClick) {
    filteredproperties = filteredproperties.filter((eachProperty) => {
      if ((eachProperty.type === "Building")
        // || (eachProperty.type === "City"
        || (eachProperty.type === "Campus")
        // )
      ) {
        return true
      }
      else {
        return false
      }
    })
  }
  filteredproperties = filteredproperties.sort((a, b) => {


    return a.order - b.order
    // if (
    //   a.type === b.type ||
    //   (a.type === "Available Space" && b.type === "Building") ||
    //   (b.type === "Available Space" && a.type === "Building") ||
    //   (a.type === "Lease Transaction" && b.type === "Building") ||
    //   (b.type === "Lease Transaction" && a.type === "Building") ||
    //   (a.type === "Lease Transaction" && b.type === "Available Space") ||
    //   (b.type === "Lease Transaction" && a.type === "Available Space")
    // ) {
    //   return a.name > b.name ? 1 : -1;
    // } else {
    //   // if (a.type !== "City" && b.type === "City") {
    //   //   return 1;
    //   // } else
    //   if (a.type === "Campus" && b.type !== "Campus") {
    //     return -1;
    //   } else if (a.type === "Contact" && b.type !== "Contact") {
    //     return 1;
    //   } else if (a.type === "Parcel" && b.type !== "Parcel") {
    //     return 1;
    //   } else if (a.type === "Building" && b.type !== "Building") {
    //     return 100;

    //   } else {
    //     // if ((a.type === "Campus") && (b.type !== "Campus")) {
    //     //   return 1
    //     // }
    //     // else {
    //     return a.type < b.type ? 1 : -1;
    //     // }
    //   }
    // }
  });

  // const debounce = (callback, wait) => {
  //   let timeoutId = null;
  //   return (...args) => {
  //     window.clearTimeout(timeoutId);
  //     timeoutId = window.setTimeout(() => {
  //       callback.apply(null, args);
  //     }, wait);
  //   };
  // }
  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };


  // const handleKeyUp = debounce((ev) => {
  //   // Do stuff with the event!
  //   if (ev.target.value.length > 2) {
  //     getSearchData(setSearchProperties, contactId, 10, ev.target.value, setWaiting, controllers, setControllers, waiting);
  //     setQuery(ev.target.value)
  //     setWaiting(false)
  //   }
  // }, 250);

  const handleKeyUp = debounce((ev) => {
    const query = ev.target.value;
    if (query.length > 2) {
      // Set waiting state to true before the async operation
      setWaiting(true);

      getSearchData(setSearchProperties, contactId, 10, query, setWaiting, controllers, setControllers, waiting)
        .then(() => {
          // Async operation succeeded, you can set states related to its success here if needed
        })
        .catch(error => {
          // Handle any error from the async operation
          console.error("Failed to fetch search data:", error);
        })
        .finally(() => {
          // Always turn off the waiting indicator
          setWaiting(false);
        });

      // Update the query state immediately as the user types
      setQuery(query);
    }
  }, 750);

  return (
    <>
      <div
        style={{ width: '100%', display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column', }}

      >
        <div className=" z-10 flex items-center" style={{ maxWidth: 900, width: (window.screen.width > 1000) && window.location.href.includes("?") ? "80%" : "100%" }} >
          <Combobox

            as="div"
            value={selectedProperty}


            onChange={(valueSelected) => {



              var searchbarUnparsed = getCookie("searchbar");

              var searchbar = {};

              if (searchbarUnparsed && (searchbarUnparsed !== "")) {
                searchbar = JSON.parse(searchbarUnparsed);

              } else {
                searchbar.entries = [];
              }



              if (searchbar && searchbar.entries && searchbar.entries.length > 0) {
                var indexOfValue = searchbar.entries.indexOf((entry) => {
                  return entry.name === valueSelected.name
                })
                if (indexOfValue === -1) {
                  searchbar.entries.push(valueSelected);
                }
              }

              searchbar.entries = removeDuplicates(searchbar.entries, 'name')

              createCookie("searchbar", JSON.stringify(searchbar), 14);


              goToParcel(valueSelected);
            }}
            id="random"
            name="random"
            style={{ width: '100%', zIndex: 102, marginRight: 5, marginLeft: 5 }}
          // className={"w-full z-102"}
          >
            {/* <Combobox.Label className="block text-sm font-medium text-gray-700">Search for Property Data</Combobox.Label> */}
            <div
              className={
                window.screen.width > 1000
                  ? "relative z-10"
                  : "relative text-xs z-102"
              }
            >
              <Tooltip onFocus={handleInputEnter} placement="top" title="Search Cities, Campus Names, People, Buildings, Availables..." arrow>
                <Combobox.Input
                  style={{ minWidth: 200, fontSize: 18, color: 'black' }}

                  className={
                    (height ? height : "") +
                    " w-full  z-10 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 text-xs sm:text-sm"
                  }

                  onKeyUp={handleKeyUp}
                  displayValue={(property) => property.name}
                  placeholder="Search ..."
                  onFocus={(event) => {
                    event.target.setAttribute("autoComplete", "off");
                    //test - console.log(event.target.autocomplete);
                  }}
                />
              </Tooltip>
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-8 focus:outline-none">
                {/* {filteredproperties.length === 0 && !firstBigSearch &&  query.length>0? <img className= "h-5 w-5" style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />:<></>}  */}
                {waiting ? (
                  <img
                    className="h-5 w-5"
                    src="https://images.cubicsearch.com/Wait.gif"
                  />
                ) : (
                  <></>
                )}
              </Combobox.Button>
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>

              {filteredproperties.length > 0 && (
                <Combobox.Options
                  style={{ borderRadius: 5, maxHeight: window.innerHeight / 2, zIndex: 2000, minWidth: 300, width: mobileAndTabletCheck() ? '100%' : '100%' }}
                  className="absolute mt-1  overflow-y-scroll  bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                  {filteredproperties.map((property, index) => (
                    <Combobox.Option
                      key={property.id + "-" + index}
                      value={property}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none z-102 pl-3 py-2 pl-8 pr-4",
                          active ? "bg-red-600 text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => {


                        const standardHTMLReturn = (property) => {

                          return (
                            <>
                              <div style={{ zIndex: 1000, display: 'flex', alignItems: 'start' }}>
                                <div style={{ marginTop: 5 }}>
                                  <img
                                    src={property?.img}
                                    alt=""
                                    className="w-10"
                                    style={{ borderRadius: 5 }}
                                  />
                                </div>
                                <div style={{ width: "100%", overflowX: 'hidden' }}>
                                  <div>
                                    <span // property type span1
                                      className={classNames(
                                        "px-4 ml-2 truncate text-gray-500",
                                        active ? "text-white" : "text-gray-500"
                                      )}
                                    >
                                      {span1Return(property)}
                                    </span>
                                  </div>
                                  <div>
                                    <span  // span2
                                      className={classNames(
                                        "px-4 block truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {span2Return(property)}
                                    </span>
                                  </div>

                                  <div>
                                    <span  // span3
                                      className={classNames(
                                        "px-4 ml-2 truncate text-gray-500",
                                        active ? "text-white" : "text-gray-500"
                                      )}
                                    >
                                      {span3Return(property)}
                                    </span>
                                  </div>
                                  <div>
                                    <span // span4
                                      className={classNames(
                                        "px-4 ml-2 truncate text-gray-500",
                                        active ? "text-white" : "text-gray-500"
                                      )}
                                    >
                                      {span4Return(property)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {selected && (
                                <span
                                  className={classNames(
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                    active ? "text-white" : "text-red-600"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>)
                        }


                        var span1Return = () => {
                          return property.type
                        }
                        var span2Return = () => {

                          // if (property.type === "City") {
                          //   return ""
                          // }
                          // else {
                          return property.address
                          // }
                        }


                        var span3Return = () => {
                          if (property.type === "Move Map Here") {
                            return (
                              <>
                                {/* <div className="border-2"> */}
                                {/* <Rating
                                  name="read-only"
                                  value={parseFloat(property.city)}
                                  readOnly
                                ></Rating> */}
                                {/* {" " + property.city} */}
                                {/* </div> */}
                              </>)
                          }
                          else {
                            return property.city
                          }
                        }

                        var span4Return = () => {
                          // if (property.type === "City") {
                          //   return ""
                          // }
                          // else {
                          return property.apn
                          // }
                        }



                        return (

                          standardHTMLReturn(property)

                        )
                      }}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        </div>
        <div className="flex  flex-col items-start px-2">
          {window.location.href.indexOf("/search") > 0 ? (
            <>
              <div className="text-xs sm:text-sm ">
                Reset {" "}
                <Switch
                  checked={!goOnClick}
                  onChange={() => {
                    setGoOnClick(!goOnClick);
                  }}
                />{" "}
                Add Results
              </div>
              <div
                className={
                  window.screen.width > 1000
                    ? "flex text-xs justify-center"
                    : "hidden"
                }
              >
                on search...
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

// Replaces oldS with newS in the string fullS
function replaceString(oldS, newS, fullS) {
  for (let i = 0; i < fullS.length; ++i) {
    if (fullS.substring(i, i + oldS.length) === oldS) {
      fullS =
        fullS.substring(0, i) +
        newS +
        fullS.substring(i + oldS.length, fullS.length);
    }
  }
  return fullS;
}

function removeDuplicates(array, propertyName) {
  return array.filter((obj, index, self) =>
    index === self.findIndex((o) => o[propertyName] === obj[propertyName])
  );
}