// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-material-ui-carousel";
import { ConvertImagePath } from "../Common/ThumbFiles";
import { randomNumberBetween } from "@mui/x-data-grid/utils/utils";
import { useEffect, useRef, useLayoutEffect } from 'react';
import axios from "axios"
import GoogleKeenSliderPhone from "./GoogleKeenSliderPhone";
import { scrambleString } from "../Common/Cookies";



const DemoCarousel = ({ images, goToURL, property }) => {


  if (images.length > 0) {

    return (
      <div >



        <ImageSlide imageArray={images} goToURL={goToURL} />

      </div>
    )
  }
  else {

    <div >
      <GoogleKeenSliderPhone dataIn={property} />
    </div>
  }


  // }

};


export const ImageSlide = ({ imageArray, goToURL }) => {
  function imageExists(image_url) {

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

  }

  return (
    <div className="self-center" key={"Demo" + randomNumberBetween(65, 0, 9999999)}>
      <Carousel
        key="2"
        autoPlay={false}
        
      // showThumbs={false}
      // showStatus={false}
      // showArrows={true}
      // onClickItem={(e) => {
      //   if (goToURL) {
      //     window.open(goToURL);
      //   }
      // }}
      >
        {
          imageArray

            .map((image, index) => {


              return (

                <div key={"image2" + image.id + index} style={{ cursor: 'pointer', borderRadius: 5, width: '100%', height: window.innerHeight/4, overflow:'hidden'}} >
                  <img
                    src={ConvertImagePath(image.img)}
                    loading="lazy"
                    style={{ width: '100%', display: 'flex', objectFit:'contain',}}
                    // className="object-cover w-full h-full rounded-2xl"
                    onClick={() => {

                      if (goToURL) {
                        window.open(goToURL);
                      }

                    }}
                    onError={({ currentTarget }) => {

                      // fetch(backup).then(() => {
                      //   currentTarget.onerror  = backup

                      axios.post(process.env.REACT_APP_DATABASE + "api/deleteImage", { img: ConvertImagePath(image.img), id: image.id }).catch((error) => {
                        console.log(error)
                      }).then((response) => {
                        console.log("response")
                      })
                      currentTarget.onerror = null
                      currentTarget.src = "https://images.cubicsearch.com/small%20logo.png";
                      currentTarget.onerror = "https://images.cubicsearch.com/small%20logo.png";
                      // })

                    }}
                  // className="rounded-2xl object-cover max-h-56 w-40 md:h-52 md:w-80 flex-shrink-0"
                  />
                  {/* <img src={image.img} loading="lazy" className="rounded-2xl h-full object-cover"  /> */}
                </div>
              )
            })


        }
      </Carousel>
    </div>
  )
}

export const PhoneDemoCarousel = ({ images, goToURL, property }) => {
  // const [backup, setBackup] = useState("https://dev.virtualearth.net/REST/V1/Imagery/Map/Birdseye/" +
  //   property.Latitude.toString().trim() +
  //   "," +
  //   property.Longitude.toString().trim() +
  //   "/20?dir=270&ms=900,700&key=AntwMqF4SuxAC8AfmB9-AuOVzrtOhbiNMZFVVe68dkbt5IjzUIzmWS8dfFmo9-IL")
  return (<DemoCarousel images={images} goToURL={goToURL} property={property} />)

};

export const SizableDemoCarousel = ({ images, goToURL }) => {

  if (images) {
    return (

      <Carousel sx={{ height: images.length >0 ? 350: 0}} key="2" autoPlay={false} showThumbs={false}>
          {
            images
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((image, index) => {

                return (

                    
                     
                  <div style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center', width: '100%', height: 300, borderRadius: 30, overflow: 'hidden'}}>
                    <img 
                      src={ConvertImagePath(image.img)}
                      loading="lazy"
                      
                    style={{width: '100%', display:'flex', objectFit:'cover' }}
                      onError={({ currentTarget }) => {


                        currentTarget.onerror = "https://images.cubicsearch.com/small%20logo.png";


                      }}
                      onClick={() => {

                        if (goToURL) {
                          window.open(goToURL);
                        }

                      }}
                    // className="rounded-2xl object-cover max-h-56 w-40 md:h-52 md:w-80 flex-shrink-0"
                    />
                   
                  </div>
                )
              }
                // }
              )
          }

        </Carousel>
    
    );
  } else {
    return <></>;
  }
};
export const ShortDemoCarousel = ({ images, goToURL }) => {

  if (images) {
    return (

      <Carousel sx={{ height: images.length > 0 ? 250 : 0 }} key="2" autoPlay={false} showThumbs={false}>
        {
          images
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((image, index) => {

              return (



                <div key={"Carousel"+(scrambleString(image.img))} style={{ display: 'flex', cursor:'pointer', justifyContent: 'center', width: '100%', height: 200, borderRadius: 5, overflow: 'hidden' }}>
                  <img
                    src={ConvertImagePath(image.img)}
                    loading="lazy"

                    style={{ width: '100%', display: 'flex', objectFit: 'cover', borderRadius: 5 }}
                    onError={({ currentTarget }) => {


                      currentTarget.onerror = "https://images.cubicsearch.com/small%20logo.png";


                    }}
                    onClick={() => {

                      if (goToURL) {
                        window.open(goToURL);
                      }

                    }}
                  // className="rounded-2xl object-cover max-h-56 w-40 md:h-52 md:w-80 flex-shrink-0"
                  />

                </div>
              )
            }
              // }
            )
        }

      </Carousel>

    );
  } else {
    return <></>;
  }
};
export default DemoCarousel;

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>
